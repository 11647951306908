// Your variable overrides


// Custom.scss
// Option B: Include parts of Bootstrap

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Optional
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";


//squarespace font
@font-face{font-family:proxima-nova;src:url(https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?subset_id=2&fvd=n4&v=3) format("woff2"),url(https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?subset_id=2&fvd=n4&v=3) format("woff"),url(https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?subset_id=2&fvd=n4&v=3) format("opentype");font-weight:400;font-style:normal;}@font-face{font-family:proxima-nova;src:url(https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?subset_id=2&fvd=n6&v=3) format("woff2"),url(https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?subset_id=2&fvd=n6&v=3) format("woff"),url(https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?subset_id=2&fvd=n6&v=3) format("opentype");font-weight:600;font-style:normal;}@font-face{font-family:futura-pt;src:url(https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?subset_id=2&fvd=n4&v=3) format("woff2"),url(https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?subset_id=2&fvd=n4&v=3) format("woff"),url(https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?subset_id=2&fvd=n4&v=3) format("opentype");font-weight:400;font-style:normal;}@font-face{font-family:futura-pt;src:url(https://use.typekit.net/af/309dfe/000000000000000000010091/27/l?subset_id=2&fvd=n7&v=3) format("woff2"),url(https://use.typekit.net/af/309dfe/000000000000000000010091/27/d?subset_id=2&fvd=n7&v=3) format("woff"),url(https://use.typekit.net/af/309dfe/000000000000000000010091/27/a?subset_id=2&fvd=n7&v=3) format("opentype");font-weight:700;font-style:normal;}@font-face{font-family:futura-pt;src:url(https://use.typekit.net/af/eb729a/000000000000000000010092/27/l?subset_id=2&fvd=i7&v=3) format("woff2"),url(https://use.typekit.net/af/eb729a/000000000000000000010092/27/d?subset_id=2&fvd=i7&v=3) format("woff"),url(https://use.typekit.net/af/eb729a/000000000000000000010092/27/a?subset_id=2&fvd=i7&v=3) format("opentype");font-weight:700;font-style:italic;}


//colors
$grey: #999;

//image
img {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

//fonts
h1, h2, h3, h4, h5, p, li, a {
    font-family: futura-pt;
}


body {
    background-color: black;
}

.container {
    background-color: white;
    padding: 0 3em;
}

nav.navbar {
    padding-top: 3em;
    li {
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 3px;
        margin-left: 10px;
        a {
            color: $grey;
            text-decoration: none;
            &:hover {
                color: black;
            }
            
        }
        .active {
            a {
                color: black;
            }
            
        }
    }
    .dropdown {
        a {
            font-size: 14px;
            
        }
        a.current {
            color: black;
        }
        a.dropdown-item {
            &:active {
 background-color: transparent;
            }
           
        }
    }
}

button.navbar-toggler {
    border: none;

}

ul.navbar-nav {
 li {
     height: auto;
     padding: 20px 0;
 }
}

button:focus {
    outline: none;
  }


p.home-intro {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.8;
}

p.normal {
    font-size: 18px;
    line-height: 1.5em;
    font-weight: 300;
    margin-top: 1em;
}

h2 {
    font-size: 26px;
    line-height: 1.3em;
    font-weight: 700;
    margin-top: 1.5em;
}

h3 {
    font-weight: 100;
    font-size: 22px;
}

ul.content-ul {
    li {
        font-size: 20px;
        line-height: 2.5em;
        font-weight: 800;

    }
}

.list-row {
    padding: 3em 0;
}

p.caption {
    font-size: 22px
}

footer {
    padding: 2em 0;
}

form {

    p {
        margin: 0;
        margin-top: 20px;
        margin-bottom: 10px;
        font-family: futura-pt;
        font-size: 18px;
        
    }
    input {
        width: 100%;
        min-height: 50px;
    }

    input.btn-send {
        width: auto;
        margin-top: 40px;
        background-color: black;
        color: white;
        text-transform: uppercase;
        padding: 10px 40px;
        border-radius: 5px;
        margin-bottom: 40px;
    }

    textarea {
        width: 100%;
    }

    label {
        font-size: 12px;
        font-family: futura-pt;
    }
}

a.email {
    color: #f8d930;
}